import React, { useState } from 'react';
import './product-item.scss';
import busket2 from '../../assets/img/busket2.png';
import { useDispatch } from 'react-redux';
import { productPostAddToCart } from '../../redux/services/cart-services';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toggleDetailModal } from '../../redux/actions/control-action';
import { getProductDetailPage } from '../../redux/services/product-services';

const ProductItem = (props) => {
    const [count, setCount] = useState(1);
    const [imgActive, setActive] = useState(true);
    const [imgBlock, setBlock] = useState(false);
    const { images, variants, name, uuid } = props;
    const dispatch = useDispatch();


    const Min = () => {
        setCount(count => count === 1 ? 1 : count - 1);
    }
    const Plus = () => {
        setCount(count => count + 1);
    }
    const addToCart = () => {
        setTimeout(() => {
            setActive(true)
        }, 1500);
        setActive(false)
        dispatch(productPostAddToCart({ variant: variants[0].id, quantity: count }))
    }

    const toDetailPage = () => {
        dispatch(getProductDetailPage(uuid))
        dispatch(toggleDetailModal(true))
    }

    return (
        <div className="product-item">
            <div
                onClick={() => setBlock(!imgBlock)}
                className={imgBlock ? 'product-item__image-item-absolute ' : ' product-item__image-item-absolute-block '}>
                <img src={images[0] && `${images[0].url}?w=120&h=120`} alt="" />
            </div>
            <div onClick={toDetailPage} className='product-item__image'>
                <img
                    className={imgActive ? 'product-item__image-item' : 'product-item__image-item-block'}
                    src={images[0] && `${images[0].url}?w=120&h=120`} alt="" />
                <img
                    className={imgActive ? 'product-item__image-item' : 'product-item__image-item_active'}
                    src={images[0] && `${images[0].url}?w=120&h=120`} alt=""
                />
            </div>
            <div onClick={toDetailPage} className="product-item__text">
                <h3 className='product-item__title'>{name}</h3>
                <span className='product-item__description'>{variants[0] && variants[0].descriptions}</span>
                <div className='product-item__short-fade'>
                    <span className='action'></span>
                </div>
                <span className='product-item__size'>{variants[0] && variants[0].size}: {variants[0] && variants[0].size_unit.name}</span>
            </div>
            <div className='product-item__form'>
                <div className="product-item__count">
                    <div className="product-item__count-btns">
                        <span onClick={Min}
                            className='product-item__count-btn'>-</span>
                        <span>|</span>
                        <span>{count}</span>
                        <span>|</span>
                        <span onClick={Plus}
                            className='product-item__count-btn'>+</span>
                    </div>
                </div>
                <div className="product-item__btn-group">
                    <div className="product-item__btn-sale">
                        <span className='product-item__sale_price'>{variants[0] && variants[0].sale_price}₽</span>
                        {variants[0] && variants[0].price && <span className='product-item__price'>{variants[0] && variants[0].price}₽</span>}
                    </div>
                    <div className="product-item__btn-group">
                        <div className='product-item__icon'>
                            <OverlayTrigger overlay={<Tooltip>Хочу!</Tooltip>}>
                                <div
                                    onClick={addToCart}
                                    className='product-item__btn'>
                                    <img
                                        className={imgActive ?
                                            'product-item__image-basket'
                                            :
                                            'product-item__image-basket product-item__image-basket_active'}
                                        src={busket2} alt="" />
                                    <span>Хочу</span>
                                </div>
                            </OverlayTrigger>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductItem;