import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
    closeSignIgBtn,
    openRegisterBtn,
    openRecoverBtn,
} from "../../../redux/actions/auth-action";
import "./sign-in.scss";
import TelegramLoginButton from 'react-telegram-login';
import authServices from '../../../services/auth-services';
import { getMeAuth } from "../../../redux/services/auth-services";
import Cookies from "js-cookie";
const SignIn = (props) => {
    const { closeSignIgBtn, signInShow } = props;
    const dispatch = useDispatch();

    const handleTelegramResponse = async response => {
        try {
            const res = await authServices.telegramBotUser(response)
            Cookies.set('token-sushi', res.data['token'])
            dispatch(getMeAuth())
            setTimeout(() => {
                dispatch(closeSignIgBtn())
            }, 2000)
        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div className="sign-in">
            <Modal show={signInShow} onHide={closeSignIgBtn}>
                <Modal.Header
                    style={{ borderBottom: "none" }}
                    closeButton
                ></Modal.Header>
                <div className="sign-in__title">
                    <h1>Войти</h1>
                </div>
                <Modal.Body>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <TelegramLoginButton
                            dataOnauth={handleTelegramResponse}
                            botName="sushitime47Bot" />
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="bottom-center" />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        signInShow: state.authReducer.signInShow,
        token: state.authReducer.token,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        openRegisterBtn: () => dispatch(openRegisterBtn()),
        closeSignIgBtn: () => dispatch(closeSignIgBtn()),
        openRecoverBtn: () => dispatch(openRecoverBtn()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
