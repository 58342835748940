import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom"
import HomePage from "../pages/home-page";
import OrderPage from "../pages/order-page";
import BasketPage from "../pages/basket-page";
import ContactPage from "../pages/contact-page";
import PersonalPage from "../pages/personal-page";
import OrderPaymentStatus from "../pages/order-payment-status";
import PromotionPage from "../pages/promotion-page";
import HistoryOrderPage from "../pages/history-order-page";
import * as Scroll from 'react-scroll'
const Router = () => {
    const { pathname } = useLocation();

    const scroll = Scroll.animateScroll;
    useEffect(() => {
        scroll.scrollTo(0, 0)
    }, [pathname, scroll]);

    return (
        <Routes>
            <Route exact path='/' element={<HomePage />} />
            <Route exact path='/order-page' element={<OrderPage />} />
            <Route exact path="/basket-page" element={<BasketPage />} />
            <Route exact path='/contact-page' element={<ContactPage />} />
            <Route exact path="/personal-page" element={<PersonalPage />} />
            <Route exact path='/order-page-payment' element={<OrderPaymentStatus />} />
            <Route exact path="/promotion-page" element={<PromotionPage />} />
            <Route exact path="/history-order" element={<HistoryOrderPage/>}/>
        </Routes>
    )
}

export default Router;