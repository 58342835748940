import BasketPageOrder from './basket-page-order';
import { useSelector } from 'react-redux';
import CartItem from '../../components/cart-item/cart-item';
import { useMemo } from 'react';
import './basket-page.scss';

const BasketPage = () => {
  const { carts, errorMessage } = useSelector((state) => state.productReducer);

  const totalCount = useMemo(() => {
    return carts.cart_items.reduce((prev, curr) => prev + curr.quantity, 0);
  }, [carts.cart_items]);

  const { promo_code_for_products } = carts;

  const elements = useMemo(() => {
    return carts.cart_items && carts.cart_items.length === 0 ? (
      <h2 className="basket-page__empty">Корзина пусто</h2>
    ) : (
      carts.cart_items.map((data, index) => <CartItem key={index} {...data} />)
    );
  }, [carts.cart_items]);

  return (
    <div className="basket-page">
      <h1 className="basket-page__title">Корзина</h1>
      <div className="basket-page__product">
        {elements}
        {promo_code_for_products !== null ? (
          <div className="basket-page__promo-product">
            <div className="basket-page__promo-product-img">
              <img
                src={`${promo_code_for_products.variants[0].product.images[0].url}?w=211&h=211`}
                alt=""
              />
            </div>
            <div className="basket-page__promo-product-texts">
              <h2 className="basket-page__promo-product-title">
                {promo_code_for_products.variants[0].name}
              </h2>
              <div>
                <span className="basket-page__promo-product-size">
                  Количество
                </span>
                <span>
                  {promo_code_for_products.variants[0].size}{' '}
                  {promo_code_for_products.variants[0].size_unit.name}
                </span>
              </div>
              <h3 className="basket-page__promo-product-present">
                Подарочная продукция
              </h3>
            </div>
          </div>
        ) : null}
      </div>
      <BasketPageOrder
        errorMessage={errorMessage}
        totalCount={totalCount}
        carts={carts}
        total={carts && carts.total}
      />
    </div>
  );
};

export default BasketPage;
