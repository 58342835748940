import React from 'react';
import OrderProcessing from '../../components/order-processing';
import './order-page.scss';

const OrderPage = () => {

    return (
        <div className='order-page'>
            <h1 className='order-page__title'>Оформление заказа</h1>
            <div className='order-page__content'>
                <OrderProcessing />
            </div>
        </div>
    )
}

export default OrderPage;