import instance from './settings';

const getProductsServices = (page, id) => {
  let url = `product/products/?page_size=20&page=${page}`;

  if (id !== undefined && id !== null) {
    url += `&category=${id}`;
  }

  return instance.get(url);
};
const getProductsSearchServices = (body) => {
  return instance.get(`product/products/?search=${body}`);
};
const getCategoriesServices = () => {
  return instance.get('product/categories/');
};

const postAddToCartServices = (uuid, object) => {
  return instance.post(`cart/${uuid}/item/`, object);
};

const postCartUidServices = () => {
  return instance.post(`cart/`);
};

const getCartProductServices = (uuid) => {
  if (uuid !== null) {
    return instance.get(`cart/${uuid}/`);
  }
};

const postPromoCodeServices = (uuid, code) => {
  return instance.post(`cart/${uuid}/promo_code/`, { promo_code: code });
};

const getProductDetail = (uuid) => {
  return instance.get(`product/products/${uuid}`);
};
const getPromotion = () => {
  return instance.get(`/cart/promotion/`);
};
const getPromotionUiid = (uuid) => {
  return instance.get(`/cart/promotion/${uuid}`);
};
const productServices = {
  getProductsServices,
  getCategoriesServices,
  postAddToCartServices,
  postCartUidServices,
  getCartProductServices,
  postPromoCodeServices,
  getProductDetail,
  getPromotion,
  getPromotionUiid,
  getProductsSearchServices,
};

export default productServices;
