import {
    LOAD_CART_PRODUCT,
    PROMO_CODE,
    ADD_PRODUCT_TO_CART,
    COUPON
} from '../types';

export const errorMessageAction = (message) => {
    return {
        type: 'ERROR_MESSAGE',
        payload: message
    }
}

export const postAddToCart = (data) => {
    return {
        type: ADD_PRODUCT_TO_CART,
        payload: data
    }
}
export const getCartProduct = (data) => {
    return {
        type: LOAD_CART_PRODUCT,
        payload: data
    }
}

export const postPromoCode = (data) => {
    return {
        type: PROMO_CODE,
        payload: data
    }
}
export const couponAction = (coupon) => {
    return {
        type: COUPON,
        payload: coupon
    }
}
