import React from 'react';
import './about-text.scss';

const AboutTexts = () => {
  return (
    <div className='about-texts'>
      <div className='about-texts__item'>
        <h1 className='about-texts__item-title'>Добро пожаловать в Суши Тайм!</h1>
        <p className='about-texts__item-text'>Побалуйте себя вкусными суши вместе с Sushi Time! Оцените удобство заказа пиццы, роллов, горячих блюд, сетов, салатов, десертов и напитков, не выходя из дома.</p>
      </div>
      <div className='about-texts__item'>
        <h1 className='about-texts__item-title'>Откройте для себя вкус свежести!</h1>
        <p className='about-texts__item-text'>В Sushi Time мы стремимся предлагать вам блюда, приготовленные только из самых свежих ингредиентов. Наша страсть - предоставить вам уникальный и вкусный опыт.</p>
      </div>
      <div className='about-texts__item'>
        <h1 className='about-texts__item-title'>Удобство во всей красе!</h1>
        <p className='about-texts__item-text'>Заказать любимую еду еще никогда не было так просто. С Sushi Time вы можете воспользоваться быстрой доставкой по городу и бесплатной доставкой в черте города. Плюс заказы принимаются ежедневно с 11.00 до 24.00!</p>
      </div>
      <div className='about-texts__item'>
        <h1 className='about-texts__item-title'>Вкус вне всякого сравнения</h1>
        <p className='about-texts__item-text'>Мы стремимся подарить вам уникальный кулинарный опыт с каждым приемом пищи. В нашем меню представлены разнообразные классические и современные суши-роллы, а также горячие блюда, салаты, десерты и напитки.</p>
      </div>
    </div>
  );
};

export default AboutTexts;