import {BURGER_OPEN, BURGER_CLOSE, OPEN_DETAIL_MODAL} from "../types";

const initialState = {
    active: false,
    modal: false
}

export const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case BURGER_OPEN:
            return {
                ...state,
                active: state.active = true
            }
        case BURGER_CLOSE:
            return {
                ...state,
                active: state.active = false
            }
        case OPEN_DETAIL_MODAL: {
            return {
                ...state,
                modal: action.payload
            }
        }
        default:
            return state

    }
}