import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {burgerClose} from "../../../../redux/actions/burger-action";

import { openSignIgBtn } from "../../../../redux/actions/auth-action";
const HeaderNavbarList = () => {
    const {username} = useSelector(state => state.authReducer.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <>
            <li><Link onClick={() => dispatch(burgerClose())} to="/">Главная</Link></li>
            <li><a onClick={() => {
                dispatch(burgerClose())
                navigate('/')
            }} href="#menu">Меню</a></li>
            <li><Link onClick={() => dispatch(burgerClose())} to="/promotion-page">Акции</Link></li>
            <li><Link onClick={() => dispatch(burgerClose())} to="/basket-page">Корзина</Link></li>
            <li><Link onClick={() => dispatch(burgerClose())} to="/order-page">Оформление заказа</Link></li>
            <li><Link onClick={() => dispatch(burgerClose())} to="/contact-page">Контакты</Link></li>
            {
                username ?
                <div className='private'>
                    <li>
                        <Link
                            onClick={() => dispatch(burgerClose())}
                            to='/personal-page'>Личный кабинет</Link>
                    </li>
                </div>
                : <div className="signIn">
                    <button onClick={() => dispatch(openSignIgBtn())} className="btn">войти</button>
                </div>
            }
        </>
    )
}

export default HeaderNavbarList;