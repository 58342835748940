import './order-processing-cash.scss';


const OrderProcessingCash = (props) => {
    const {register} = props;
    return (
        <div className="order-processing-cash">
            <div className="order-processing-cash__group">
                <div className="order-processing-cash__group_payment">
                    <span>Оплата:</span>
                </div>
                <div className="order-processing-cash__group-card">
                    <input
                        {...register('payments', {required: true})}
                        value='cash'
                        type="radio"
                        name='payments'/>
                    <label htmlFor="">Наличными</label>
                </div>
                <div className="order-processing-cash__group-cash">
                    <input
                        {...register('payments', {required: true})}
                        value='card'
                        type="radio"
                        name='payments'/>
                    <label htmlFor="">Картой</label>
                </div>
            </div>
        </div>
    )
}

export default OrderProcessingCash;