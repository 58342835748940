import React, { useMemo } from 'react';
import './promotion-page.scss';
import { useSelector } from 'react-redux';
import PromotionItem from '../../components/promotion-item';
import Loading from '../../components/loading';

const PromotionPage = () => {
  const { promotion, isLaodingAllPromotion } = useSelector(
    (state) => state.productReducer
  );

  const promotionPage = useMemo(() => {
    return promotion.map((item) => <PromotionItem {...item} key={item.uuid} />);
  }, [promotion]);

  if (isLaodingAllPromotion) {
    return <Loading />;
  }

  return (
    <div className="promotion-page">
      <h1 className="promotion-page__title">Акции</h1>
      <div className="promotion-page__wrapper">{promotionPage}</div>
    </div>
  );
};

export default PromotionPage;
