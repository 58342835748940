import orderServices from "../../services/order-services";
import { orderLoading, errorBuy, loadingToggleAction } from '../actions/control-action';
import { orderDataAction, orderPaymentAction, historyOrderAction, lookOrderAction } from "../actions/order-action";
import { purchaseOpen } from "../actions/auth-action";
import { productPostCartUuid } from "./cart-services";
import {toast} from 'react-toastify'
export const postOrder = (object) => {
    return async (dispatch) => {
        try {
            dispatch(orderLoading(true))
            dispatch(errorBuy(false))
            const getLocal = JSON.parse(localStorage.getItem('cart-uuid'))
            if (!getLocal) {
                dispatch(productPostCartUuid())
            } else {
                const response = await orderServices.orderPostServices(object)
                localStorage.setItem('order-uuid', JSON.stringify(response.data.uuid))
                localStorage.removeItem('cart-uuid');
                dispatch(orderLoading(false))
                if (response.data.pay_url === '') {
                    dispatch(getOrder())
                    dispatch(purchaseOpen())
                    dispatch(errorBuy(false))
                } else {
                    window.location.assign(response.data.pay_url)
                }
            }
        } catch (e) {
            toast.error(e.response.data.message)
            dispatch(errorBuy(true))
            setTimeout(() => {
                dispatch(errorBuy(false))
            }, 3000)
            dispatch(orderLoading(false))
        }
    }
}

export const getOrder = () => {
    return async (dispatch) => {
        try {
            const order_uuid = JSON.parse(localStorage.getItem('order-uuid'));
            const response = await orderServices.orderGetUuidServices(order_uuid);
            dispatch(orderDataAction(response.data))
            dispatch(errorBuy(false))
        } catch (e) {
            dispatch(errorBuy(true))
            setTimeout(() => {
                dispatch(errorBuy(false))
            }, 3000)
        }
    }
}
export const lookOrder = (uuid) => {
    return async (dispatch) => {
        try {
            const response = await orderServices.orderGetUuidServices(uuid)
            dispatch(lookOrderAction(response.data))
        } catch (e) {

        }
    }
}

export const getPaymentStatus = () => {
    return async (dispatch) => {
        try {
            const order_uuid = JSON.parse(localStorage.getItem('order-uuid'));
            const response = await orderServices.orderPaymentStatus(order_uuid);
            dispatch(orderPaymentAction(response.data))
            if (response.data.orderStatus === 1) {
                dispatch(getOrder())
            }
            dispatch(errorBuy(false))
        } catch (e) {
            dispatch(errorBuy(true))
            setTimeout(() => {
                dispatch(errorBuy(false))
            }, 3000)
        }
    }
}

export const getMyOrder = () => {
    return async (dispatch) => {
        dispatch(loadingToggleAction(true))
        try {
            const response = await orderServices.orderMyServices();
            dispatch(historyOrderAction(response.data));
            dispatch(loadingToggleAction(false))
        } catch (e) {
            dispatch(loadingToggleAction(false))
        }
    }
}