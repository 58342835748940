import { loadingToggleAction, errorData } from '../actions/control-action';
import {
  loadProduct,
  loadCategories,
  searchLoad,
  detailAction,
  loadPromotionAction,
  loadPromotionUuidAction,
  toggleIdProduct,
  changeTitleAction,
  loadingDetailProduct,
  loadingPromotion,
  loadingAllPromotion
} from '../actions/products-action';
import productServices from '../../services/product-services';

export const productGetData = (page, id) => async (dispatch) => {
  try {
    dispatch(loadingToggleAction(true));
    dispatch(errorData(false));
    const response = await productServices.getProductsServices(page, id);
    let hasMore = response.data.results.length === 20;
    dispatch(loadProduct(response.data, hasMore));
    dispatch(errorData(false));
    dispatch(loadingToggleAction(false));
  } catch (e) {
    dispatch(loadingToggleAction(false));
    dispatch(errorData(true));
  }
};

export const categoryGetData = () => {
  return async (dispatch) => {
    dispatch(loadingToggleAction(true));
    dispatch(errorData(false));
    try {
      const response = await productServices.getCategoriesServices();
      dispatch(loadCategories(response));
      dispatch(loadingToggleAction(false));
      dispatch(toggleIdProduct(response.data.results[0].id));
      dispatch(productGetData(1, response.data.results[0].id));
      dispatch(errorData(false));
    } catch (e) {
      dispatch(errorData(true));
      setTimeout(() => {
        dispatch(errorData(false));
      }, 2000);
      dispatch(loadingToggleAction(false));
    }
  };
};

export const productGetSearch = (value) => {
  return async (dispatch) => {
    dispatch(loadingToggleAction(true));
    try {
      const response = await productServices.getProductsSearchServices(value);
      if (response.data.results.length !== 0) {
        dispatch(searchLoad(response.data.results));
        dispatch(toggleIdProduct(response.data.results[0].category.id));
        dispatch(changeTitleAction(response.data.results[0].category.name));
      } else {
        alert('Извините, нет такого продукта');
      }
    } catch (e) {
      dispatch(errorData(true));
      setTimeout(() => {
        dispatch(errorData(false));
      }, 2000);
      dispatch(loadingToggleAction(false));
    }
  };
};

export const getProductDetailPage = (uuid) => {
  return async (dispatch) => {
    dispatch(loadingDetailProduct(true));
    try {
      const data = await productServices.getProductDetail(uuid);
      await dispatch(detailAction(data.data));
      dispatch(loadingDetailProduct(false));
    } catch (e) {
      dispatch(loadingDetailProduct(false));
    }
  };
};

export const PromotionServices = () => {
  return async (dispatch) => {
    dispatch(loadingAllPromotion(true));
    try {
      const response = await productServices.getPromotion();
      dispatch(loadPromotionAction(response.data.results));
      dispatch(loadingAllPromotion(false));
    } catch (e) {
      dispatch(loadingAllPromotion(false));
    }
  };
};

export const getPromotionUuidServices = (uuid) => {
  return async (dispatch) => {
    dispatch(loadingPromotion(true));
    try {
      const response = await productServices.getPromotionUiid(uuid);
      dispatch(loadPromotionUuidAction(response.data));
      dispatch(loadingPromotion(false));
    } catch (e) {
      dispatch(loadingPromotion(false));
    }
  };
};
