import React, { useState } from 'react';
import './product-related.scss';
import { useDispatch } from 'react-redux';
import { productPostAddToCart } from '../../redux/services/cart-services';
const ProductRelated = (props) => {
    const [isActive, setIsActive] = useState(false);
    const [count, setCount] = useState(1);
    const dispatch = useDispatch();
    const handleClick = () => {
        setIsActive(!isActive)
        dispatch(productPostAddToCart({ variant: variants[0].id, quantity: count }))
    }
    const min = () => {
        setIsActive(count === 1 ? false : true)
        setCount(count => count === 1 ? 1 : count - 1)
        dispatch(productPostAddToCart({ variant: variants[0].id, quantity: count - 1 }))

    }
    
    const plus = () => {
        setCount(count => count + 1)
        dispatch(productPostAddToCart({ variant: variants[0].id, quantity: count + 1 }))

    }
    
    const { images, name, variants } = props;
    return (
        <div style={{ border: isActive ? "1px solid #FF6B00" : null }}
            className='product-related'>
            <div onClick={handleClick} className='product-related__img'>
                <img src={images && images[0].url} alt="" />
            </div>
            <h4>{name}</h4>
            <div className='product-related__bying'>
                <div className='product-related__price'>{variants && variants[0].sale_price}p</div>
                {isActive ? <div>
                    <button onClick={min}>-</button>
                    <span>{count}</span>
                    <button onClick={plus}>+</button>
                </div> : <button onClick={handleClick}>Хочу</button>}
            </div>
        </div>
    );
};

export default ProductRelated;