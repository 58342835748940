import { loadingToggleAction, errorData, } from '../actions/control-action';
import { postAddToCart, getCartProduct, postPromoCode } from "../actions/cart-action";
import productServices from "../../services/product-services";
import {toast} from 'react-toastify';
export const productPostCartUuid = (object) => {
    return async (dispatch) => {
        try {
            const response = await productServices.postCartUidServices()
            localStorage.setItem('cart-uuid', JSON.stringify(response.data.uuid))
            const getLocal = JSON.parse(localStorage.getItem('cart-uuid'))
            const res = await productServices.postAddToCartServices(getLocal, object)
            dispatch(postAddToCart(res.data))
            dispatch(errorData(false))
        } catch (e) {
            dispatch(errorData(true))
        }
    }
}

export const productPostAddToCart = (object) => {
    return async (dispatch) => {
        const getLocal = JSON.parse(localStorage.getItem('cart-uuid'))
        try {
            if (!getLocal) {
                dispatch(productPostCartUuid(object))
            } else {
                const response = await productServices.postAddToCartServices(getLocal, object)
                dispatch(postAddToCart(response.data))
                dispatch(errorData(false))
            }
        } catch (e) {
            dispatch(errorData(true))
        }
    }
}

export const promoCode = (code) => {
    return async (dispatch) => {
        try {
            const getLocal = JSON.parse(localStorage.getItem('cart-uuid'))
            const response = await productServices.postPromoCodeServices(getLocal, code);
            dispatch(postPromoCode(response.data.promo_code_for_products))
            toast.success('Промокод успешно активирован')
        } catch (e) {
            dispatch(errorData(true))
            setTimeout(() => {
                dispatch(errorData(false))
            }, 2000)
            dispatch(loadingToggleAction(false))
            toast.error(e.response.data.message)
        }
    }
}

export const loadCartProduct = () => {
    return async (dispatch) => {
        try {
            const getLocal = JSON.parse(localStorage.getItem('cart-uuid'))
            const response = await productServices.getCartProductServices(getLocal)
            dispatch(getCartProduct(response))
            dispatch(errorData(false))
        } catch (e) {
            dispatch(errorData(true))
        }
    }
}
