import './slider.scss';
import { Carousel } from 'react-bootstrap';
import sliderImg2 from '../../assets/img/cover_pk.jpg'
import {Link} from 'react-router-dom';
const Slider = () => {
    return (
        <div className="slider">
            <Carousel variant="dark">
                <Carousel.Item>
                    <Link to='/promotion-page'>
                        <img
                            className="d-block w-100"
                            src={sliderImg2}
                            alt="First slide"
                        />
                    </Link>
                </Carousel.Item>
                <Carousel.Item>
                    <Link to='/promotion-page'>
                        <img
                            className="d-block w-100"
                            src={sliderImg2}
                            alt="First slide"
                        />
                    </Link>
                </Carousel.Item>
                <Carousel.Item>
                    <Link to='/promotion-page'>
                        <img
                            className="d-block w-100"
                            src={sliderImg2}
                            alt="First slide"
                        />
                    </Link>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Slider;