import React, { useEffect, useCallback } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import Auth from './components/auth';
import Main from './pages/main';
import Purchase from './components/purchase-modal';
import { useDispatch, useSelector } from 'react-redux';
import { getMeAuth } from './redux/services/auth-services';
import './App.scss';
import { PromotionServices } from './redux/services/product-services';
import { categoryGetData } from './redux/services/product-services';
import DetailPage from './pages/detail-page';
import DetailPromotion from './components/detail-promotion';
import DetailOrder from './components/detail-order';
import { postPromoCode } from './redux/actions/cart-action';
import { loadCartProduct } from './redux/services/cart-services';
import Cookies from 'js-cookie';
const App = () => {
  const dispatch = useDispatch();
  const token = Cookies.get('token-sushi');
  const { carts } = useSelector((state) => state.productReducer);

  const loadUser = useCallback(() => {
    if (token) {
      dispatch(getMeAuth());
    }
  }, [token, dispatch]);

  const loadPromo = useCallback(() => {
    dispatch(PromotionServices());
  }, [ dispatch]);

  const loadCategory = useCallback(() => {
    dispatch(categoryGetData());
  }, [ dispatch]);


  const loadProduct = useCallback(() => {
    dispatch(loadCartProduct());
  }, [ dispatch]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  useEffect(() => {
    loadPromo();
  }, [loadPromo]);


  useEffect(() => {
    loadCategory()
  }, [loadCategory]);

  useEffect(() => {
    loadProduct()
  }, [loadProduct]);

  useEffect(() => {
    if (carts.cart_items.length === 0) {
      dispatch(postPromoCode(null));
    }
  }, [carts.cart_items.length, dispatch]);

  return (
    <div className="app">
      <Header />
      <Auth />
      <Purchase />
      <DetailPage />
      <DetailPromotion />
      <DetailOrder />
      <Main />
      <Footer />
    </div>
  );
};

export default App;
