import {combineReducers} from 'redux';
import {authReducer} from './auth-reducer';
import {modalReducer} from './modal-reducer';
import {productReducer} from './product-reducer';
import {globalReducer} from "./global-reducer";
import {personReducer} from "./person-reducer";
import {orderReducer} from "./order-reducer";

export const rootReducer = combineReducers({
    authReducer,
    modalReducer,
    productReducer,
    globalReducer,
    orderReducer,
    personReducer
})