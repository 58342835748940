import {ORDER_GET_DATA, ORDER_PAYMENT_STATUS, HISTORY_ORDER, LOOK_ORDER,ORDER_DETAIL} from "../types";


export const toggleDetailModalAction = (bool) => {
    return {
        type: ORDER_DETAIL,
        payload: bool
    }
}
export const orderDataAction = (data) => {
    return {
        type: ORDER_GET_DATA,
        payload: {data}
    }
}
export const lookOrderAction = (data) => {
    return {
        type: LOOK_ORDER,
        payload: data
    }
}
export const orderPaymentAction = (data) => {
    return {
        type: ORDER_PAYMENT_STATUS,
        payload: {data}
    }

}

export const historyOrderAction = (data) => {
    return {
        type: HISTORY_ORDER,
        payload: data
    }
}
