import React, { useState } from 'react';
import './personal-page.scss';
import logo from '../../assets/img/login.png';
import logout1 from '../../assets/img/logout.png';
import { useDispatch, useSelector } from "react-redux";
import { logout } from '../../redux/actions/auth-action';
import Cookie from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { patchPersonService, putPersonService } from "../../redux/services/person-services";
import { getMyOrder } from '../../redux/services/order-services';

const PersonalPage = () => {
    const data = useSelector(state => state.authReducer.user)
    const { personLoading } = useSelector(state => state.personReducer)
    const [firstName, setFirstName] = useState(data.first_name);
    const [lastName, setLastName] = useState(data.last_name);
    const [birthday, setBirthday] = useState(data.birthday);
    const [email, setEmail] = useState(data.email);
    const [gender, setGender] = useState(data.gender);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = () => {
        const initialState = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            gender: gender,
            birthday: birthday
        }
        dispatch(patchPersonService(initialState))
        dispatch(putPersonService(initialState))
    }
    const handlerLogout = () => {
        dispatch(logout())
        Cookie.remove('token-sushi')
        navigate('/');
    }
    const onCheckHistory = () => {
        navigate('/history-order')
        dispatch(getMyOrder())
    }
    return (
        <div className='personal'>
            <h1 className='personal__title'>Личный кабинет</h1>
            <div className='personal__wrapper'>
                <span onClick={onCheckHistory} className='personal__history'>история заказов</span>
                <div className="personal__left">
                    <img src={logo} alt="" />
                </div>
                <div className="personal__right">
                    <h2> Мой профиль</h2>
                    <div className='personal__form'>
                        <div className="personal__form-control ">
                            <label htmlFor="">Имя пользователя:</label>
                            <input
                                onChange={(e) => setFirstName(e.target.value)}
                                value={firstName}
                                type="text" />
                        </div>
                        <div className="personal__form-control ">
                            <label htmlFor="">Фамилия пользователя:</label>
                            <input
                                onChange={(e) => setLastName(e.target.value)}
                                value={lastName}
                                type="text" />
                        </div>
                        <div className="personal__form-control ">
                            <label htmlFor="">дата рождение:</label>
                            <input
                                className='personal__calendar'
                                type='date'
                                placeholder={'YYY-MM-DD'}
                                onChange={(e) => setBirthday(e.target.value)}
                                value={birthday}
                            />
                        </div>
                        <div className="personal__form-control ">
                            <label htmlFor="">e-mail</label>
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                type="email" />
                        </div>
                        <div className="personal__form-control">
                            <label htmlFor="">пол:</label>
                            <div className='personal__radio'>
                                <label htmlFor="">мужской</label>
                                <input
                                    checked={gender === 'male'}
                                    onChange={(e) => setGender(e.target.value)}
                                    value={'male'}
                                    name={'gender'}
                                    type="radio" />
                                <label htmlFor="">женский</label>
                                <input
                                    checked={gender === 'female'}
                                    name={'gender'}
                                    onChange={(e) => setGender(e.target.value)}
                                    value={'female'}
                                    type="radio" />
                            </div>
                        </div>
                    </div>
                    <div className="personal__btn-group">
                        <button
                            onClick={handleSubmit}
                            className='personal__change'>
                            {personLoading ? 'загрузка..' : ' Изменить профиль'}
                        </button>
                        <button
                            onClick={handlerLogout}
                            className='personal__logout '>
                            Выйти
                            <img src={logout1} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalPage;