import {BURGER_OPEN} from '../types';
import {BURGER_CLOSE} from '../types';

export const burgerOpen = () => {
    return {
        type: BURGER_OPEN,
    }
}

export const burgerClose = () => {
    return {
        type: BURGER_CLOSE
    }
}