import {
    PRODUCT_LOAD,
    LOADING_TOGGLE_ACTION,
    ERROR_DATA,
    CATEGORIES_LOAD,
    LOAD_CART_PRODUCT,
    SEARCH_PRODUCT_LOAD,
    PROMO_CODE,
    ADD_PRODUCT_TO_CART,
    DELETE_CART,
    DETAIL_PAGE,
    CHANGE_TITLE,
    GET_PROMOTION,
    GET_PROMOTION_UUID,
    CURRENT_PAGE,
    TOGGLE_PRODUCT_ID,
    COUPON
} from '../types';

const initialState = {
    data: [],
    search: {
        count: 0,
        results: []
    },
    category: {
        results: []
    },
    cart_items: [],
    carts: {
        uuid: '',
        cart_items: [],
        total: 0,
        promo_code: null,
        promo_code_for_products: null
    },
    isLoadingData: false,
    isLoadingDetailProduct: false,
    isLaodingPromotion: false,
    isLaodingAllPromotion: false,
    error: null,
    errorMessage: null,
    product: {},
    title: null,
    promotion: [],
    promotionItem: {},
    currentPage: 1,
    _id: null,
    hasMore: true
}

export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ERROR_MESSAGE": {
            return {
                ...state,
                errorMessage: action.payload
            }
        }
        case COUPON: {
            return {
                ...state,
                carts: {
                    ...state.carts,
                    promo_code: action.payload
                }
            }
        }
        case TOGGLE_PRODUCT_ID: {
            return {
                ...state,
                _id: action.payload,
                data: [],
                currentPage: 1
            }
        }

        case GET_PROMOTION_UUID: {
            return {
                ...state,
                promotionItem: action.payload
            }
        }
        case GET_PROMOTION: {
            return {
                ...state,
                promotion: action.payload
            }
        }
        case CURRENT_PAGE: {
            return {
                ...state,
                currentPage: state.currentPage + 1
            }
        }
        case PRODUCT_LOAD: {
            return {
                ...state,
                data: [...state.data, ...action.payload.results],
                hasMore: action.hasMore,
            };
        }
        case CHANGE_TITLE: {
            return {
                ...state,
                title: action.payload
            }
        }
        case ADD_PRODUCT_TO_CART: {
            if (action.payload === "") {
                return state
            }
            const newCartItems = [...state.carts.cart_items]
            const index = newCartItems.findIndex(item => item.variant.id === action.payload.variant.id)
            if (index !== -1) {
                newCartItems[index] = action.payload
            } else {
                newCartItems.push(action.payload)
            }
            return {
                ...state,
                carts: {
                    ...state.carts,
                    cart_items: newCartItems,
                    total: newCartItems.reduce((ans, item) => ans + item.total, 0)
                }
            }
        }
        case DELETE_CART: {
            const newCartItems = state.carts.cart_items.filter(item => item.variant.id !== action.payload.id)
            return {
                ...state,
                carts: {
                    ...state.carts,
                    cart_items: newCartItems,
                    total: newCartItems.reduce((ans, item) => ans + item.total, 0)
                }
            }
        }
        case SEARCH_PRODUCT_LOAD:
            return {
                ...state,
                data: action.payload,
            }
        case CATEGORIES_LOAD:
            return {
                ...state,
                category: action.payload.data,
            }
        case LOAD_CART_PRODUCT:
            return {
                ...state,
                carts: action.payload.data,
            }
        case PROMO_CODE:
            return {
                ...state,
                carts: {
                    ...state.carts,
                    promo_code_for_products: action.payload
                }
            }
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                isLoadingData: action.payload,
            }
        case ERROR_DATA:
            return {
                ...state,
                error: action.payload,
            }
        case DETAIL_PAGE: {
            return {
                ...state,
                product: action.payload
            }
        }
        case "LOADING_DETAIL_PRODUCT": {
          return {
            ...state,
            isLoadingDetailProduct: action.payload
          }
        }
        case "LOADING_PROMOTION": {
          return {
            ...state,
            isLaodingPromotion: action.payload
          }
        }
        case "LOADING_ALL_PROMOTION": {
          return {
            ...state,
            isLaodingAllPromotion: action.payload
          }
        }
        default:
            return state
    }
}

