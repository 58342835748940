import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ModalPromotion } from '../../redux/actions/products-action';
import Loading from '../loading';
const DetailPromotion = () => {
  const dispatch = useDispatch();
  const { promotion } = useSelector((state) => state.modalReducer);
  const { promotionItem, isLaodingPromotion } = useSelector(
    (state) => state.productReducer
  );

  const handleClose = () => {
    dispatch(ModalPromotion(false));
  };
  const { title, short_body } = promotionItem;
  return (
    <Modal show={promotion} onHide={handleClose}   >
      {isLaodingPromotion ? (
        <Loading />
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{short_body}</Modal.Body>
        </>
      )}
    </Modal>
  );
};

export default DetailPromotion;
