import React from 'react';
import './header-contact.scss'
import LocationIcon from '../../../assets/img/ic-location.png';
import PhoneIcon from '../../../assets/img/ic-phone.png';
import Time from '../../../assets/img/time.png'
import { Container } from 'react-bootstrap';
import { openSignIgBtn } from '../../../redux/actions/auth-action';
import { useDispatch} from 'react-redux';
const HeaderContact = ({logout, username}) => {
    const dispatch = useDispatch()
    const onOpenSignIn = () => {
        dispatch(openSignIgBtn())
    }
    return (
        <div className='header-contact'>
            <Container>
                <div className="header-contact__content">
                    <div className="header-contact__left">
                        <h1>Выбери свой город</h1>
                        <select>
                            <option value="">Кириши</option>
                        </select>
                    </div>
                    <div className="header-contact__info">
                        <div className="header-contact__item">
                            <div className='header-contact__icon'>
                                <img src={Time} alt="" />
                            </div>
                            <div className='header-contact__text'>
                                <span className='header-contact__text-top'>Мы работаем</span>
                                <span className='header-contact__text-bottom'>с 11:00 до 24:00</span>
                            </div>
                        </div>
                        <div className="header-contact__item">
                            <div className='header-contact__icon'>
                                <img src={PhoneIcon} alt="" />
                            </div>
                            <div className='header-contact__text'>
                                <span className='header-contact__text-top'>Номер телефона</span>
                                <span className='header-contact__text-bottom'>+7 (904) 633-46-33
                                </span>
                            </div>
                        </div>
                        <div className="header-contact__item">
                            <div className='header-contact__icon'>
                                <img src={LocationIcon} alt="" />
                            </div>
                            <div className='header-contact__text'>
                                <span className='header-contact__text-top'>Локация</span>
                                <span className='header-contact__text-bottom'>ул. Волховская набережная <br /> д. 40, г. Кириши</span>
                            </div>
                        </div>
                    </div>
                    <div className='header-contact__btn'>
                        
                        {username ? <button onClick={logout}>Выйти</button> : <button onClick={onOpenSignIn}>Войти</button>}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeaderContact;