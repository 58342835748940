import authServices from '../../services/auth-services';
import { errorData, authLoading } from '../actions/control-action';
import { getMe } from '../actions/auth-action';

export const getMeAuth = () => {
    return async (dispatch) => {
        try {
            dispatch(authLoading(true))
            const response = await authServices.getMeServices()
            dispatch(getMe(response.data))
            dispatch(errorData(false))
            dispatch(authLoading(false))
        } catch (e) {
            dispatch(errorData(true))
            dispatch(authLoading(false))
        }
    }
}