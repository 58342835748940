import { API_URL } from './api-url'
import Cookies from 'js-cookie';
import axios from 'axios';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type" : "application/json"
    }
})

instance.interceptors.request.use((config) => {
    const token = Cookies.get('token-sushi');
    if (token) {
        config.headers.common.Authorization = `Token ${token}`
    }
    return config
}, (error) => {
    return Promise.reject(error)
})

instance.interceptors.response.use((config) => {
    return config
}, (error) => {
    if (error.response.ok) {
        throw new Error(`${error.config.url} not found`);
    }
    return Promise.reject(error)
})

export default instance;