import personServices from "../../services/person-services";
import { errorData, } from '../actions/control-action';
import { personChangeAction, personLoadingAction } from "../actions/person-actions";

export const patchPersonService = (object) => {
    return async (dispatch) => {
        dispatch(personLoadingAction(true))
        try {
            const response = await personServices.patchUserServices(object);
            dispatch(personLoadingAction(false))
            dispatch(personChangeAction(response.data))
        } catch (e) {
            dispatch(errorData(true))
            dispatch(personLoadingAction(false))
            setTimeout(() => {
                dispatch(errorData(false))
            }, 3000)
        }
    }
}

export const putPersonService = (object) => {
    return async (dispatch) => {
        dispatch(personLoadingAction(true))
        try {
            const response = await personServices.putUserServices(object);
            dispatch(personLoadingAction(false))
            dispatch(personChangeAction(response.data))
        } catch (e) {
            dispatch(errorData(true))
            dispatch(personLoadingAction(false))
            setTimeout(() => {
                dispatch(errorData(false))
            }, 3000)
        }
    }
}
