import React from 'react';
import { useSelector } from 'react-redux';
import './history-order-page.scss';

const HistoryOrderPage = () => {
    const { history } = useSelector(state => state.orderReducer);
    if(history.length === 0) {
        return <h4 className='history-order-page__title'>Извините, вы еще не заказали</h4>
    }

    return (
        <div className='history-order-page'>
            <h1 className='history-order-page__title'>История заказов</h1>
            <div className="history-order-page__wrapper">
                {
                    history && history.map((item, index) => {
                        return <div key={index} className='history-order-page__item'>
                            <div className='history-order-page__item-left'>
                                <h4 className='history-order-page__item-title'>продукция №{index + 1}</h4>
                                <p>Оплата: {item.is_paid ?
                                    <span className='history-order-page__paid'>оплачено!</span>
                                    : <span className='history-order-page__not-paid'>не оплачено!</span>
                                }</p>
                                <p>имя заказчика: {item.name}</p>
                                <p>Адрес: {item.address}</p>
                                <p>общий счет: {item.total}</p>
                            </div>
                            {/* <div className='history-order-page__item-btn'>
                                <button onClick={() => {
                                    dispatch(lookOrder(item.uuid))
                                    dispatch(toggleDetailModalAction(true))
                                }}>посмотреть детали</button>
                            </div> */}
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default HistoryOrderPage;