import './order-processing.scss';
import OrderProcessingContact from './order-processing-contact';
import OrderProcessingCash from './order-processing-cash';
import OrderProcessingList from './order-processing-list';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { postOrder } from "../../redux/services/order-services";
import {  useEffect, useState } from "react";

const OrderProcessing = () => {
    const dispatch = useDispatch();
    const [deliveryMethod, setDeliveryMethod] = useState('delivery');
    const handleDeliveryMethodChange = (method) => {
        setDeliveryMethod(method);
    };
    const { loading, error } = useSelector(state => state.orderReducer)
    const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm();

    useEffect(() => {
        const savedData = JSON.parse(localStorage.getItem('sushi-form'));
        if (savedData) {
            Object.keys(savedData).forEach((key) => {
                setValue(key, savedData[key]);
            });
        }
    }, [setValue]);

    const onSubmit = (state) => {
        const uuid = JSON.parse(localStorage.getItem('cart-uuid'));
        const object = {
            name: state.name,
            surname: state.surname,
            phone: state.phone,
            order_type: state.order_type,
            address: state.address,
            house: state.house,
            entrance: state.entrance,
            apartment_number: state.apartment_number,
            floor: state.floor,
            payments: state.payments,
            cart: uuid
        }
        dispatch(postOrder(object))
    }
    useEffect(() => {
        localStorage.setItem('myFormData', JSON.stringify(getValues()));
    }, [getValues]);
    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="order-processing">
            <div className="order-processing__address">
                <OrderProcessingContact
                    deliveryMethod={deliveryMethod}
                    handleDeliveryMethodChange={handleDeliveryMethodChange}
                    register={register}
                    errors={errors} />
            </div>
            <div className="order-processing__card-group">
                <OrderProcessingCash
                    register={register}
                    errors={errors} />
            </div>
            <div className="order-processing__order-list">
                <OrderProcessingList
                    deliveryMethod={deliveryMethod} 
                    loading={loading} 
                    onSubmit={onSubmit} />
            </div>
            {error ? <h4 style={
                { color: "red", fontSize: '18px' }
            }>ой что то пошел не так</h4> : null}
        </form>
    )
}

export default OrderProcessing;