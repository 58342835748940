import instance from "./settings";

const orderPostServices = (object) => {
    return instance.post('order/', object)
}

const orderGetUuidServices = (uuid) => {
    return instance.get(`order/${uuid}`)
}

const orderMyServices = () => {
    return instance.get('order/my_orders/')
}

const orderPaymentStatus = (uuid) => {
    return instance.get(`/order/${uuid}/get_payment_card_status/`)
}

const orderServices = {
    orderPostServices,
    orderGetUuidServices,
    orderMyServices,
    orderPaymentStatus
}

export  default  orderServices;