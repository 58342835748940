import {
    PRODUCT_LOAD,
    CATEGORIES_LOAD,
    SEARCH_PRODUCT_LOAD,
    DELETE_CART,
    DETAIL_PAGE,
    CHANGE_TITLE,
    GET_PROMOTION,
    PROMOTION,
    GET_PROMOTION_UUID,
    CURRENT_PAGE,
    TOGGLE_PRODUCT_ID,
    PRODUCT_LOAD_FIlTER
} from "../types";

export const filterProduct = (data) => {
    return {
        type: PRODUCT_LOAD_FIlTER,
        payload: data
    }
}

export const toggleIdProduct = (id) => {
    return {
        type: TOGGLE_PRODUCT_ID,
        payload: id,
    }
}

export const nextPage = () => {
    return {
        type: CURRENT_PAGE
    }
}

export const loadPromotionUuidAction = (data) => {
    return {
        type: GET_PROMOTION_UUID,
        payload: data
    }
}
export const ModalPromotion = (bool) => {
    return {
        type: PROMOTION,
        payload: bool
    }
}
export const loadPromotionAction = (data) => {
    return {
        type: GET_PROMOTION,
        payload: data
    }
}
export const changeTitleAction = (title) => {
    return {
        type: CHANGE_TITLE,
        payload: title
    }
}
export const loadProduct = (data, hasMore) => {
    return {
        type: PRODUCT_LOAD,
        payload: data,
        hasMore
    }
}

export const loadCategories = (data) => {
    return {
        type: CATEGORIES_LOAD,
        payload: data
    }
}

export const searchLoad = (data) => {
    return {
        type: SEARCH_PRODUCT_LOAD,
        payload: data
    }
}

export const loadingDetailProduct = (bool) => {
  return {
    type: "LOADING_DETAIL_PRODUCT",
    payload: bool,
  }
}

export const loadingPromotion = (bool) => {
  return {
    type: "LOADING_PROMOTION",
    payload: bool,
  }
}
export const loadingAllPromotion = (bool) => {
  return {
    type: "LOADING_ALL_PROMOTION",
    payload: bool,
  }
}



export const deleteCart = (id) => {
    return {
        type: DELETE_CART,
        payload: {id}
    }
}

export const detailAction = (product) => {
    return {
        type: DETAIL_PAGE,
        payload: product
    }
}

