import './purchase-modal-item.scss';

const PurchaseModalItem = (props) => {
    const {phone, discount, total_cart, total, order_items} = props.order;
    
    return (
        <div className='purchase-modal-list'>
            <div className="purchase-modal-list__item">
                <span className='text'>Тел</span>
                <span className='dashed'></span>
                <span className='text'>{phone}</span>
            </div>
            {
                order_items.map((elem, index) => {
                    return <>
                        <div key={index} className="purchase-modal-list__item">
                            <span className='text'>{elem.variant.name}</span>
                            <span className='dashed'></span>
                            <span className='text'>{elem.price}₽</span>
                        </div>
                        <div className="purchase-modal-list__item">
                            <span className='text'>Количество</span>
                            <span className='dashed'></span>
                            <span className='text'>{elem.quantity}шт</span>
                        </div>
                    </>
                })
            }

            {discount !== 0 ?
                <div className="purchase-modal-list__item">
                    <span className='text'>Скидка</span>
                    <span className='dashed'></span>
                    <span className='text'>{discount}Р</span>
                </div>
                :
                null
            }
            {total ?
                <div className="purchase-modal-list__item">
                    <span className='text'>Общая сумма</span>
                    <span className='dashed'></span>
                    <span className='text'>{total}₽</span>
                </div>
                :
                <div className="purchase-modal-list__item">
                    <span className='text'>Общая сумма</span>
                    <span className='dashed'></span>
                    <span className='text'>{total_cart}₽</span>
                </div>
            }
        </div>
    )
}
export default PurchaseModalItem;