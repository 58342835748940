import React from "react";
import ContactPageAdress from "./contact-page-adress";
import ContactPageMap from "./contact-page-map";
import "./contact-page.scss";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-page__title">Контакты</h1>
      <div className="contact-page__content">
        <ContactPageAdress />
        <ContactPageMap />
      </div>
    </div>
  );
};

export default ContactPage;
