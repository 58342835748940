import React from 'react';
import './contact-page-adress.scss'
import LocationIcon from '../../../assets/img/Location.png'
import PhoneIcon from '../../../assets/img/Phone.png'
import MailIcon from '../../../assets/img/Mail.png'
import StoreIcon from '../../../assets/img/Store.png'
import HoursIcon from '../../../assets/img/24-hours 1.png'
import OrangePhoneIcon from '../../../assets/img/Orange phone.png'

const ContactPageAdress = () => {

    const adress = [
        { text: 'ул. Волховская набережная д. 40, г. Кириши', icon: LocationIcon, href: 'https://www.google.com/maps/place/%D0%A1%D1%83%D1%88%D0%B8+%D0%A2%D0%B0%D0%B9%D0%BC/@59.448221,32.004779,16z/data=!4m5!3m4!1s0x0:0x7025ab87c5fedd4c!8m2!3d59.4482211!4d32.0047788?hl=ru' },
        { text: '+7 (904) 63-34-633', icon: PhoneIcon, href: 'tel:+79046334633' },
        { text: 'sushitime47@yandex.ru', icon: MailIcon, href: 'mailto:sushitime47@yandex.ru' },
        { text: 'ИП Вагабов Рамазан Магомедович ОГРНИП 323057100100410 ИНН 050200028238', icon: StoreIcon, href: '' },
        { text: 'График работы с 11:00 до 24:00', icon: HoursIcon, href: '' },
        { text: 'Связаться с директором', icon: OrangePhoneIcon, href: 'mailto:sushitime47@yandex.ru' }
    ]


    return (
        <div className='contact-page-adress'>
            <div className="contact-page-adress__content">
                <div className="contact-page-adress__text">
                    <h1>Адрес</h1>
                    <ul>
                        {
                            adress.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <img src={item.icon} alt="" />
                                        <a href={item.href}>{item.text}</a>
                                    </li>
                                )
                            }
                            )
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default ContactPageAdress;