import './home-page.scss';
import Slider from '../../components/slider';
import { useSelector, useDispatch } from 'react-redux';
import AboutTexts from '../../components/about-texts';
import { productGetData } from '../../redux/services/product-services';
import MenuProducts from '../../components/menu-products';
import {
  changeTitleAction,
  nextPage,
  toggleIdProduct,
} from '../../redux/actions/products-action';
import HeaderSearch from '../../components/header/header-search';
import cartImg from '../../assets/img/busket.png';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowUpCircleFill } from 'react-icons/bs';
import * as Scroll from 'react-scroll';
import { useCallback, useEffect, useState } from 'react';
const HomePage = () => {
  const navigate = useNavigate();
  const { category, carts, _id, currentPage } = useSelector(
    (state) => state.productReducer
  );

  console.log(_id);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const scroll = Scroll.animateScroll;

  const handleClickUp = () => {
    scroll.scrollTo(400, 400);
  };
  const handleClick = (id, title) => {
    dispatch(toggleIdProduct(id));
    dispatch(changeTitleAction(title));
    dispatch(productGetData(1, id));
  };

  const loadProduct = useCallback(() => {
    if (_id !== null) {
      dispatch(productGetData(currentPage, _id));
    }
  }, [currentPage, _id, dispatch]);

  useEffect(() => {
    loadProduct();
  }, [loadProduct]);

  const fetchMoreData = () => {
    setPage(page + 1);
    dispatch(nextPage());
  };

  const toggleInActiveStyle = (id) => {
    if (_id === id) {
      return 'active a';
    } else {
      return 'a';
    }
  };

  return (
    <div className="home-page">
      <Slider />
      <h1 className="home-page__text">Меню</h1>
      <div id="menu" className="home-page__list">
        {category.results &&
          category.results.map((elem) => {
            return (
              <div
                className={toggleInActiveStyle(elem.id)}
                key={elem.id}
                onClick={() => handleClick(elem.id, elem.name)}
              >
                {elem.name}
              </div>
            );
          })}
        <div
          onClick={() => navigate('/basket-page')}
          className="home-page__cart"
        >
          <div className="home-page__cart-icon">
            <img src={cartImg} alt="" />
            <span className="home-page__cart-count">
              {carts && carts.cart_items.length}
            </span>
          </div>
          <span className="home-page__total">{carts && carts.total} p</span>
        </div>
      </div>
      <HeaderSearch />
      <div className="home-page__products">
        <MenuProducts fetchMoreData={fetchMoreData} />
      </div>
      <div onClick={handleClickUp} className="home-page__up">
        <div className="home-page__iconUp">
          <BsFillArrowUpCircleFill size={40} color={'#f85c12'} />
        </div>
      </div>
      <AboutTexts />
    </div>
  );
};

export default HomePage;
