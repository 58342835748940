import {
    PURCHASE_OPEN,
    PURCHASE_CLOSE,
    FIXED,
    PROMOTION,
    ORDER_DETAIL
} from '../types';

const initialState = {
    purchase: false,
    fixed: false,
    promotion: false,
    look: false
}
export const modalReducer = (state = initialState, action) => {
    const {payload, type} = action
    switch(type) {
        case ORDER_DETAIL: {
            return {
                ...state,
                look: payload
            }
        }
        case PROMOTION: {
            return {
                ...state,
                promotion: payload
            }
        }
        case PURCHASE_OPEN:
            return {
                ...state,
                purchase: state.purchase = true
            }
        case PURCHASE_CLOSE:
            return {
                ...state,
                purchase: state.purchase = false
            }
        case FIXED:
            return {
                ...state,
                fixed: !state.fixed
            }
        default:
            return state
    }
}
