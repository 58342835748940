import React, { useEffect } from 'react';
import './order-payment-status.scss';
import Logo from "../../assets/img/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentStatus } from "../../redux/services/order-services";

const OrderPaymentStatus = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPaymentStatus())
    }, [dispatch]);

    const { order_payment } = useSelector(state => state.orderReducer);
    return (
        <div className='order-payment-status'>
            <h1 className='order-page__title'>Статус Оформление покупка</h1>
            <div className="order-payment-status__content">
                <div className='purchase-modal order-payment-status__order-text'>
                    <div className='purchase-modal__title'>
                        {order_payment.orderStatus === 2 ? <span className='thanks'>Спасибо за покупку</span> : null}
                    </div>
                    <div className='purchase-modal__logo'>
                        <img src={Logo} alt="" />
                    </div>
                    <div className='order-payment-status__order-status'>
                        Оформление статусу: {
                            order_payment.orderStatus === 2 ?
                                <span style={{ color: 'green' }}>ваша заяка приняты успешно!</span> :
                                <span style={{ color: 'red' }}>извините ваша заявка не приняти!</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderPaymentStatus;