import instance from "./settings";

const logoutServices = () => {
    return instance.post(`auth/logout/`)
}
const getMeServices = () => {
    return instance.get(`auth/users/me/`)
}
const pathAuthServices = (body) => {
    return instance.patch(`auth/users/me/`, body)
}
const telegramBotUser = (data) => {
    return instance.post('/telegram-bot/get_or_create/', data)
}
const authServices = {
    logoutServices,
    getMeServices,
    telegramBotUser,
    pathAuthServices
}

export default authServices;