import {PERSON_CHANGE, LOADING_PERSON_ACTION} from "../types";

export const personChangeAction = (data) => {
    return {
        type: PERSON_CHANGE,
        payload: data
    }
}

export const personLoadingAction = (bool) => {
    return {
        type: LOADING_PERSON_ACTION,
        payload: bool
    }
}
