import {
    ORDER_GET_DATA,
    ORDER_LOADING,
    ORDER_PAYMENT_STATUS,
    HISTORY_ORDER,
    LOOK_ORDER,
    
} from "../types";

const initialState = {
    loading: false,
    error: false,
    order: {
        order_items: []
    },
    order_payment: {},
    history: [],
    lookOrder: {}
}

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOOK_ORDER: {
            return {
                ...state,
                lookOrder: action.payload
            }
        }
        case HISTORY_ORDER: {
            return {
                ...state,
                history: action.payload
            }
        }
        case ORDER_GET_DATA:
            return {
                ...state,
                order: action.payload.data,
            }
        case ORDER_PAYMENT_STATUS:
            return {
                ...state,
                order_payment: action.payload,
            }
        case ORDER_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case "ERROR_BUY":
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}

