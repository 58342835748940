import React, { useState } from 'react';
import './detail-page.scss';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { toggleDetailModal } from '../../redux/actions/control-action';
import { productPostAddToCart } from '../../redux/services/cart-services';
import ProductRelated from '../../components/product-related';
import Loading from '../../components/loading';

const DetailPage = () => {
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const { product, isLoadingDetailProduct } = useSelector(
    (state) => state.productReducer
  );

  const { modal } = useSelector((state) => state.globalReducer);
  const { name, images, descriptions, variants, related_products } = product;

  const handleClose = () => {
    dispatch(toggleDetailModal(false));
  };
  const Min = () => {
    setCount((count) => (count === 1 ? 1 : count - 1));
  };
  const Plus = () => {
    setCount((count) => count + 1);
  };
  const addToCart = () => {
    dispatch(
      productPostAddToCart({ variant: variants[0].id, quantity: count })
    );
    handleClose();
  };

  return (
    <div className="detail-page">
      <Modal size="lg" show={modal} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ minHeight: 500 }}>
          {isLoadingDetailProduct ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '450px'
              }}
            >
              <Loading />
            </div>
          ) : (
            <div className="detail-page__content">
              <div className="detail-page__left">
                <div className="detail-page__image">
                  <img
                    src={images && `${images[0].url}?w=300&h=295`}
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
              <div className="detail-page__right">
                <h1>{name && name}</h1>
                <p>{descriptions}</p>
                <div className="detail-page__count">
                  <button onClick={Min}>-</button>
                  <span>{count}</span>
                  <button onClick={Plus}>+</button>
                </div>
                <h3 className="detail-page__related-text">
                  Соусы / Дополнение
                </h3>
                <div className="detail-page__related_products">
                  {related_products &&
                    related_products.map((item) => {
                      return <ProductRelated item={item.id} {...item} />;
                    })}
                </div>
              </div>
            </div>
          )}
          <div className="detail-page__price">
            <span>{variants && variants[0].sale_price}Р</span>
            <button onClick={addToCart}>Хочу</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DetailPage;
