import FooterContact from "./footer-contact";
import FooterMenuGroup from "./footer-menu-group";
import "./footer.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__top">
            <FooterContact />
            <FooterMenuGroup />
          </div>
          <div className="footer__hr"></div>
          <div className="footer__bottom">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://честныйзнак.рф/upload/conf_ru.pdf"
            >
              © 2022 Политика конфиденциальности
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
