import React, { useState} from 'react';
import './header-search.scss';
import searchIcon from '../../../assets/img/Search.png'
import { Container } from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import {productGetSearch} from "../../../redux/services/product-services";

const HeaderSearch = () => {
    const [input, setInput] = useState('');
    const dispatch = useDispatch();

    const handleKeyPress = (e) => {
        if(input !== '') {
            if(e.code === "Enter") {
                const value = input.charAt(0).toUpperCase() + input.slice(1)
                dispatch(productGetSearch(value))
                setInput('')
            }
        }
    }

    return (
        <div className='header-search'>
            <Container>
                <div className="header-search__content">
                    <div className="header-search__input-form">
                        <input
                            onKeyPress={handleKeyPress}
                            onChange={(e) => setInput(e.target.value)}
                            value={input}
                            type="text"
                            placeholder='Поиск' />
                        <img onClick={() => dispatch(productGetSearch(input))} src={searchIcon} alt="" />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeaderSearch;