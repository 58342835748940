import React, { useState } from 'react';
import classes from './product-item-adap.module.css';
import { useDispatch } from 'react-redux';
import { productPostAddToCart } from '../../redux/services/cart-services';
import { toggleDetailModal } from '../../redux/actions/control-action';
import { getProductDetailPage } from '../../redux/services/product-services';
const ProductItemAdap = (props) => {
    const { images, variants, name, uuid } = props;
    const [count, setCount] = useState(1);
    const Min = () => {
        setCount(count => count === 1 ? 1 : count - 1);
    }
    const dispatch = useDispatch();

    const Plus = () => {
        setCount(count => count + 1);
    }
    const addToCart = () => {
        dispatch(productPostAddToCart({ variant: variants[0].id, quantity: count }))
    }

    const toDetailPage = () => {
        dispatch(getProductDetailPage(uuid))
        dispatch(toggleDetailModal(true))
    }
    return (
        <div className={classes.card}>
            <div onClick={toDetailPage} className={classes.image}>
                <div className={classes.wrapper}>
                    <img src={images[0] && `${images[0].url}?w=130&h=130`} alt="" />
                </div>
            </div>
            <div className={classes.inner}>
                <h4>{name}</h4>
                <div onClick={toDetailPage} className={classes.description}>
                    {variants[0] && variants[0].descriptions}
                </div>
                <div className={classes.count}></div>
                <div onClick={toDetailPage} className={classes.fade}>
                    <span className={classes.viewProduct}></span>
                </div>
                <span className={classes.size}>{variants[0] && variants[0].size}: {variants[0] && variants[0].size_unit.name}</span>
                <div className={classes.buy}>
                    <div className={classes.price_wrapper}>
                        <div className={classes.discount}>{variants[0] && variants[0].sale_price}₽</div>
                        <div className={classes.price}>{variants[0] && variants[0].price}₽</div>
                    </div>
                    <div className={classes.quantity}>
                        <button className={classes.leftBtn} onClick={Min}>-</button>
                        <span className={classes.quantityText}>{count}</span>
                        <button className={classes.rightBtn} onClick={Plus}>+</button>
                    </div>
                    <button className={classes.buyBtn} onClick={addToCart}>Хочу</button>
                </div>
            </div>
        </div>
    );
};

export default ProductItemAdap;