import React, { useMemo } from 'react';
import './menu-products.scss';
import { useSelector } from 'react-redux';
import ProductItem from '../product-item';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductItemAdap from '../product-item-adap';
const MenuProducts = ({ fetchMoreData }) => {
  const { category, title, data, hasMore } = useSelector(
    (state) => state.productReducer
  );
  const computedTitle = useMemo(() => {
    return title === null
      ? category.results[0] && category.results[0].name
      : title;
  }, [title, category]);

  // useMemo for product items
  const productItems = useMemo(() => {
    return data.map((item, index) => <ProductItem key={index} {...item} />);
  }, [data]);

  const productItemsAdap = useMemo(() => {
    return data.map((item, index) => <ProductItemAdap key={index} {...item} />);
  }, [data]);

  return (
    <>
      <div className="menu-products">
        <h1>{computedTitle}</h1>
        <InfiniteScroll
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '50px',
          }}
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={hasMore}
        >
          {productItems}
        </InfiniteScroll>
      </div>
      <div className="menu-products-adap">
        <h1>
          {title === null
            ? category.results[0] && category.results[0].name
            : title}
        </h1>
        <InfiniteScroll
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '50px',
          }}
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={hasMore}
        >
          {productItemsAdap}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default MenuProducts;
