import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../../../assets/img/logo.png'
import './header-navbar.scss'
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { openSignIgBtn, openRegisterBtn } from '../../../redux/actions/auth-action';
import HeaderNavbarList from './header-navbar-list/header-navbar-list';
import { burgerClose, burgerOpen } from "../../../redux/actions/burger-action";
const HeaderNavbar = () => {

    const dispatch = useDispatch();
    const state = useSelector(state => state.globalReducer.active)
    const handlerClick = () => {
        if (!state) {
            dispatch(burgerOpen())
        } else {
            dispatch(burgerClose())
        }
    }

    return (
        <div className='header-navbar'>
            <Container>
                <div className="header-navbar__content">
                    <div className="header-navbar__icon">
                        <Link to='/'>
                            <img
                                onClick={() => dispatch(burgerClose())}
                                src={logo} alt="" /></Link>
                    </div>
                    <div className="header-navbar__burger-menu-active">
                        <div
                            onClick={() => handlerClick()}
                            className={state ? 'burger-menu open' : 'burger-menu'}>
                            <div className='burger-menu-down'></div>
                        </div>
                    </div>
                    <div className="header-navbar__items">
                        <ul>
                            <HeaderNavbarList
                            />
                        </ul>
                    </div>
                </div>
                <div
                    className={
                        state ?
                            "header-navbar__burger-menu open"
                            :
                            "header-navbar__burger-menu"}>
                    <div className='header-navbar__burger-menu__content'>
                        <ul>
                            <HeaderNavbarList
                            />
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        registerShow: state.authReducer.registerShow,
        signShow: state.authReducer.signShow
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        openSignIgBtn: () => dispatch(openSignIgBtn()),
        openRegisterBtn: () => dispatch(openRegisterBtn()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HeaderNavbar);  