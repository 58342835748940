import { connect } from 'react-redux';
import SignIn from './sign-in';

const Auth = ({ founds }) => {
    return (
        <div className="auth">
            {founds === 2 ? <SignIn /> : null}
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        founds: state.authReducer.founds
    }
}
export default connect(mapStateToProps)(Auth);