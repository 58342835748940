import React from 'react';
import './promotion-item.scss';
import { useDispatch } from 'react-redux';
import { ModalPromotion } from '../../redux/actions/products-action';
import { getPromotionUuidServices } from '../../redux/services/product-services';

const PromotionItem = ({ title, short_body, images, uuid }) => {

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(ModalPromotion(true));
    dispatch(getPromotionUuidServices(uuid));
  };

  return (
    <div className="promotion-item">
      <div className="promotion-item__image">
        <img
          onClick={handleClick}
          src={`${images[0] && images[0].url}?w=360&h=360`}
          alt=""
        />
      </div>
      <h2 className="promotion-item__title">{title}</h2>
      <div onClick={handleClick} className="promotion-item__short">
        <p className="promotion-item__description">{short_body}</p>
      </div>
    </div>
  );
};

export default PromotionItem;
