import instance from "./settings";

const putUserServices = (object) => {
    return instance.put('auth/users/me/', object)
}

const patchUserServices = (object) => {
    return instance.patch("auth/users/me/", object)
}

const deleteUserServices = () => {
    return instance.delete('auth/users/me/')
}

const personServices = {
    putUserServices,
    patchUserServices,
    deleteUserServices
}

export default personServices;