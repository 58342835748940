import React from 'react';
import Logo from '../../assets/img/logo.png'
import './purchase-modal.scss'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PurchaseModalItem from './purchase-modal-item';
import {purchaseClose} from '../../redux/actions/auth-action';
import Loading from "../loading";
const Purchase = () => {
    const dispatch = useDispatch()
    const order = useSelector(state => state.orderReducer.order);
    const loading = useSelector(state => state.orderReducer.loading)

    const handleClose = () => {
        dispatch(purchaseClose())
        window.location.reload()
    }
    const show = useSelector(state => state.modalReducer.purchase);
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header style={{ borderBottom: 'none' }} closeButton>
            </Modal.Header>
            <div className='purchase-modal'>
                <div className='purchase-modal__title'>
                    <span className='thanks'>Спасибо за покупку</span>
                </div>
                <div className='purchase-modal__logo'>
                    <img src={Logo} alt="" />
                </div>
                {loading ? <Loading/> : <PurchaseModalItem order={order} /> }
            </div>
        </Modal>
    )
};

export default Purchase;