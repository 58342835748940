import Phone from '../../assets/img/Phone.png'
import Location from '../../assets/img/ic-location1.png'
import './location.scss'

const LocationComponent = () => {
    return (
        <div className="location">

            <div className="location__phone">
                <div className="location__icon">
                    <img src={Phone} alt="" />
                </div>
                <a href="tel:+79046334633">+7(904) 633-46-33</a>
            </div>
            <div className="location__address">
                <div className="location__icon">
                    <img src={Location} alt="" />
                </div>
                <span>
                    ул. Волховская набережная д. 40, г. Кириши
                </span>

            </div>
        </div>
    )
}

export default LocationComponent;