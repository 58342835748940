import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toggleDetailModalAction } from '../../redux/actions/order-action';
import './detail-order.scss';

const DetailOrder = () => {
    const dispatch = useDispatch();
    const { lookOrder } = useSelector(state => state.orderReducer);
    const { look } = useSelector(state => state.modalReducer);
    const { name, total, order_items } = lookOrder;
    
    const handleClose = () => {
        dispatch(toggleDetailModalAction(false))
    }
    return (
        <Modal size='md' show={look} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{name && name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='detail-order'>
                    {
                        order_items && order_items.map(item => (
                            <div className='detail-order'>
                                <div className='detail-order__image'>
                                    <img src={`${item.variant.images[0] && item.variant.images[0].url}?w=190&h=190`} alt="" />
                                </div>
                                <p>{item.variant.name && item.variant.name}</p>
                                <p>цена: {item.variant.sale_price && item.variant.sale_price}p</p>
                            </div>
                        ))
                    }
                </div>
                <h2>общий тотал: {total && total }</h2>
            </Modal.Body>
        </Modal>
    );
};

export default DetailOrder;