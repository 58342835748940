import {ERROR_DATA, LOADING_TOGGLE_ACTION} from "../types";

const initialState = {
    error: false,
    personLoading: false,
}

export const personReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_TOGGLE_ACTION:
            return {
                ...state,
                personLoading: action.payload,
            }
        case ERROR_DATA:
            return {
                ...state,
                personLoading: state.personLoading = false,
                error: action.payload
            }
        default:
            return state
    }
}