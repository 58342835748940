import {
    REGISTER_OPEN,
    SIGN_OPEN,
    REGISTER_CLOSE,
    SIGN_CLOSE,
    RECOVERY_CLOSE,
    RECOVERY_OPEN,
    CHANGE_PASS_OPEN,
    CHANGE_PASS_CLOSE,
    AUTH_REGISTER,
    AUTH_LOGIN,
    AUT_ME,
    AUTH_LOGOUT,
    FIXED,
    PURCHASE_CLOSE,
    PURCHASE_OPEN,
    TELEGRAM_ME,
} from '../types';

export const openRegisterBtn = () => {
    return {
        type: REGISTER_OPEN
    }
}

export const closeRegisterBtn = () => {
    return {
        type: REGISTER_CLOSE
    }
}

export const openSignIgBtn = () => {
    return {
        type: SIGN_OPEN
    }
}
export const closeSignIgBtn = () => {
    return {
        type: SIGN_CLOSE
    }
}

export const openRecoverBtn = () => {
    return {
        type: RECOVERY_OPEN
    }
}
export const closeRecoverBtn = () => {
    return {
        type: RECOVERY_CLOSE
    }
}

export const purchaseOpen = () => {
    return {
        type: PURCHASE_OPEN
    }
}
export const purchaseClose= () => {
    return {
        type: PURCHASE_CLOSE
    }
}

export const fixed = () => {
    return {
        type: FIXED
    }
}

export const changeBtnPassOpen = () => {
    return {
        type: CHANGE_PASS_OPEN,
    }
}

export const changeBtnPassClose = () => {
    return {
        type: CHANGE_PASS_CLOSE,
    }
}

export const register = () => {
    return {
        type: AUTH_REGISTER,
    }
}
export const login = () => {
    return {
        type: AUTH_LOGIN,
    }
}

export const getMe = (data) => {
    return {
        type : AUT_ME,
        payload: {data}
    }
}

export const telegramMe = (data) => {
    return {
        type: TELEGRAM_ME,
        payload: {data}
    }
}

export const logout = () => {
    return {
        type: AUTH_LOGOUT,
    }
}



