import './cart-item.scss';
import del from '../../assets/img/delete.png'
import {useDispatch} from 'react-redux';
import {productPostAddToCart} from '../../redux/services/cart-services';
import {deleteCart} from "../../redux/actions/products-action";
import {useEffect, useState} from "react";

const CartItem = (props) => {
    const {name, product, id} = props.variant;
    const {price, total, quantity} = props;
    const [count, setCount] = useState(quantity)
    const dispatch = useDispatch();


    useEffect(() => {
        if (count === 0) {
            dispatch(deleteCart(id))
        }
        dispatch(productPostAddToCart({variant: id, quantity: count}))
    }, [count, dispatch, id])


    const deleteItem = () => {
        dispatch(productPostAddToCart({variant: id, quantity: 0}))
        dispatch(deleteCart(id))
    }

    return (
        <div className="cart-item">
            <div className="cart-item__images">
                <img src={product.images[0] && `${product.images[0].url}?w=280&h=280`} alt=""/>
            </div>
            <div className="cart-item__parent">
                <h2>{name}</h2>
                <div className="cart-item__group">
                    <div className="cart-item__counts">
                        <span className='cart-item__text'>Количество</span>
                        <div className="cart-item__btn">
                            <span className='cart-item__count' onClick={() => setCount((count) => count - 1)}>-</span>
                            <span className='cart-item__litle-title'>{count}</span>
                            <span className='cart-item__count' onClick={() => setCount((count) => count + 1)}>+</span>
                        </div>
                    </div>
                    <div className="cart-item__price">
                        <span className='cart-item__text'>Цена</span>
                        <span className='cart-item__litle-title'>{price && price} ₽</span>
                    </div>
                    <div className="cart-item__total-price">
                        <span className='cart-item__text'>Конечная цена</span>
                        <span className='cart-item__litle-title'>{total && total} ₽</span>
                    </div>
                </div>
                <img
                    onClick={deleteItem}
                    className='cart-item__delete' src={del} alt={del.name}/>
            </div>
        </div>
    )
}

export default CartItem;