import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { promoCode } from '../../../redux/services/cart-services';
import { ToastContainer } from "react-toastify";
const BasketPageOrder = (props) => {
    const [coupon, setCoupon] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { total, totalCount, carts } = props;
    const handleClick = () => {
        navigate('/order-page')
    }
    const discount = carts.promo_code && carts.promo_code.discount_percent

    const totalDiscount = discount !== null ? total * (1 - discount / 100) : total

    const handleClickPromo = () => {
        if (coupon !== '') {
            setCoupon('')
            dispatch(promoCode(coupon))
        }
    }
    return (
        <>
            <div className="basket-page__sale">
                <div className="basket-page__order">
                    <span>Количество заказов: {totalCount} шт</span>
                </div>
                <div className="basket-page__total">
                    <span>Общая сумма: {Math.floor(totalDiscount)}₽</span>
                    <br />
                    {
                        discount !== null ? <span style={{color: '#F47C0D'}}>Скидка {discount}%</span> : null
                    }
                </div>
            </div>
            <div className="basket-page__pay-order">
                <div className="basket-page__apply-promo">
                    <input
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        name="coupon"
                        type="text"
                        placeholder='Промокод' />
                    <span onClick={handleClickPromo}>Применить</span>
                </div>
                <div className="basket-page__pay">
                    <span onClick={handleClick}>Оформить заказ</span>
                </div>
            </div>
            <ToastContainer theme='dark' position='top-center' />
        </>
    )
}

export default BasketPageOrder;