import {
    LOADING_TOGGLE_ACTION,
    ERROR_DATA,
    AUTH_LOADING,
    ORDER_LOADING,
    OPEN_DETAIL_MODAL
} from '../types';

export const loadingToggleAction = (bool) => {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: bool
    };
}
export const authLoading = (loading) => {
    return {
        type: AUTH_LOADING,
        payload: loading
    }
}
export const orderLoading = (loading) => {
    return {
        type: ORDER_LOADING,
        payload: loading
    }
}
export const errorData = (error) => {
    return {
        type: ERROR_DATA,
        payload: error
    }
}

export const errorBuy = (error) => {
    return {
        type: "ERROR_BUY",
        payload: error
    }
}

export const toggleDetailModal = (bool) => {
    return {
        type: OPEN_DETAIL_MODAL,
        payload: bool
    }
}