import {
    REGISTER_OPEN,
    SIGN_OPEN,
    REGISTER_CLOSE,
    SIGN_CLOSE,
    RECOVERY_CLOSE,
    RECOVERY_OPEN,
    CHANGE_PASS_CLOSE,
    CHANGE_PASS_OPEN,
    AUTH_REGISTER,
    AUTH_LOADING,
    ERROR_DATA,
    AUTH_LOGIN,
    AUT_ME,
    AUTH_LOGOUT,
    TELEGRAM_ME,
    PERSON_CHANGE
} from '../types';

const initialState = {
    registerShow: false,
    signInShow: false,
    recoveryShow: false,
    changeShow: false,
    founds: 1,
    loading: false,
    errorData: false,
    user: {}
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case TELEGRAM_ME: {
            return {
                ...state,
                user: action.payload.data
            }
        }
        case PERSON_CHANGE: {
            return {
                ...state,
                user: action.payload
            }
        }
        case AUTH_LOGIN:
            return {
                ...state,
                loading: state.loading = false,
                errorData: state.errorData = false
            }
        case AUTH_REGISTER:
            return {
                ...state,
                loading: state.loading = false,
                errorData: state.errorData = false
            }
        case AUT_ME: 
            return {
                ...state,
                user: action.payload.data,
                loading: state.loading = false,
                errorData: state.errorData = false
            }
        case AUTH_LOGOUT:
            return  {
                ...state,
                user: {},
            }
        case ERROR_DATA: {
            return {
                ...state,
                errorData: action.payload,
                loading: state.loading = false,
            }
        }
        case AUTH_LOADING: 
            return {
                ...state,
                loading: action.payload,
            }
        case REGISTER_OPEN:
            return {
                ...state,
                registerShow: state.registerShow = true,
                founds: state.founds = 1
            }
        case SIGN_OPEN:
            return {
                ...state,
                signInShow: state.signInShow = true,
                founds: state.founds = 2
            }
        case REGISTER_CLOSE:
            return {
                ...state,
                registerShow: state.registerShow = false,
            }
        case SIGN_CLOSE:
            return {
                ...state,
                signInShow: state.signInShow = false,
            }
        case RECOVERY_CLOSE:
            return {
                ...state,
                recoveryShow: state.recoveryShow = false
            }
        case RECOVERY_OPEN:
            return {
                ...state,
                recoveryShow: state.recoveryShow = true,
                founds: state.founds = 3
            }
        case CHANGE_PASS_OPEN:
            return {
                ...state,
                changeShow: state.changeShow = true,
                founds: state.founds = 4
            }
        case CHANGE_PASS_CLOSE:
            return {
                ...state,
                changeShow: state.changeShow = false,
            }
        default:
            return state
    }
}
