import './error-message.scss';

const ErrorMessage = (props) => {

    return (
        <div className='error-message'>
            {props.children}
        </div>
    )
}

export default ErrorMessage;