import './footer-menu-group.scss';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logout, openRegisterBtn, openSignIgBtn} from '../../../redux/actions/auth-action';
import Cookie from "js-cookie";

const FooterMenuGroup = () => {
    const user = useSelector(state => state.authReducer.user);
    const dispatch = useDispatch();
    const handleOpenClick = () => {
        dispatch(openSignIgBtn())
    }
    const handleClickLogout = () => {
        dispatch(logout())
        Cookie.remove('token-sushi')
    }
    const onUpPage = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className='footer-menu-group'>
            <div className="footer-menu-group__home">
                <span className='title'>Главная</span>
                <div className='footer-menu-group__list'>
                    <span><Link to={'/'} onClick={onUpPage}>Меню</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Рекламы</Link></span>
                </div>
            </div>
            <div className="footer-menu-group__menu">
                <span className='title'>Меню</span>
                <div className='footer-menu-group__list'>
                    <span><Link to={'/'} onClick={onUpPage}>Все</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Фирменные роллы</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Горячие блюда</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Наборы</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Акции</Link></span>
                    <span><Link to={'/'} onClick={onUpPage}>Десерты</Link></span>
                </div>
            </div>
            <div className="footer-menu-group__checkout">
                <span className='title'>Оформить заказ</span>
                <div className='footer-menu-group__list'>
                    <span><Link to={'/basket-page'}>Корзина</Link></span>
                </div>
            </div>
            <div className="footer-menu-group__contact">
                <span className='title'>Контакты</span>
                <div className='footer-menu-group__list'>
                    <span><Link to={'/contact-page'}>Адрес</Link></span>
                    <span><Link to={'/contact-page'}>Номер телефона</Link></span>
                    <span><Link to={'/contact-page'}>Карта</Link></span>
                    <span><Link to={'/contact-page'}>Время работы</Link></span>
                    <span><Link to={'/contact-page'}>sushitime47@gmail.com</Link></span>
                </div>
            </div>
            <div className="footer-menu-group__registration">
                <span className='title'>Регистрация</span>
                <div className='footer-menu-group__list'>
                    {
                        user.username ?
                            <>
                                <span><Link to={'/personal-page'}>Личный кабинет</Link></span>
                                <span><Link onClick={handleClickLogout} to=''>Выйти</Link></span>
                            </>
                            : <>
                                <span className='footer-menu-group__auth' onClick={() => dispatch(openRegisterBtn())}>Регистрация</span>
                                <span className='footer-menu-group__auth' onClick={handleOpenClick}>Войти</span>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default FooterMenuGroup;