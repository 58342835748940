import HeaderContact from './header-contact';
import HeaderNavbar from './header-navbar';
import Cookie from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/auth-action';
import authServices from '../../services/auth-services';

const Header = () => {
    const { user } = useSelector(state => state.authReducer);
    const dispatch = useDispatch()
    const Logout = async () => {
        Cookie.remove('token-sushi')
        dispatch(logout())
        await authServices.logoutServices()
    }
    return (
        <div className="header">
            <HeaderContact
                username={user.username}
                logout={Logout} />
            <HeaderNavbar />
        </div>
    )
}

export default Header;