import './order-processing-contact.scss';
import ErrorMessage from "../../error-message";

const OrderProcessingContact = (props) => {

    const { register, errors, deliveryMethod, handleDeliveryMethodChange } = props;
    return (
        <div className="order-processing-contact">
            <div className="order-processing-contact__group">
                <div className="order-processing-contact__name">
                    <label htmlFor="">Ваше имя</label>
                    <input
                        {...register('name', {
                            required: true
                        })}
                        type="text"
                        style={{ borderColor: errors?.name ? "red" : null }}
                        placeholder='Введите ваше имя' />
                    {errors?.name && <ErrorMessage>Поле обязательно к заполнению!</ErrorMessage>}
                </div>
                <div className="order-processing-contact__surname">
                    <label htmlFor="">Ваше Фамилия</label>
                    <input
                        {...register('surname')}
                        type="text"
                        placeholder='Введите ваше Фамилия' />
                </div>
                <div className="order-processing-contact__phone">
                    <label htmlFor="">Ваш телефон</label>
                    <input
                        {...register('phone', {
                            required: true,
                            minLength: {
                                value: 12,
                                message: "Минимум 12 символов."
                            }
                        })}
                        style={{ borderColor: errors?.phone ? "red" : null }}
                        type="text"
                        placeholder='+7----------' />
                    {errors?.phone &&
                        <ErrorMessage>{errors?.phone.message || 'Поле обязательно к заполнению'}</ErrorMessage>}
                </div>
            </div>
            <div className="order-processing-contact__type">
                <div className="order-processing-contact__type-text">
                    <span>Тип заказа:</span>
                </div>
                <div className="order-processing-contact__type-delivery">
                    <input
                        {...register('order_type')}
                        checked={deliveryMethod === 'delivery' ? true : false}
                        value="delivery"
                        name='order_type'
                        id='delivery'
                        onClick={() => handleDeliveryMethodChange('delivery')}
                        type="radio" />
                    <label htmlFor="delivery">Доставка</label>
                </div>
                <div className="order-processing-contact__type-pickup">
                    <input
                        {...register('order_type')}
                        value="pickup"
                        checked={deliveryMethod === 'pickup' ? true : false}
                        name='order_type'
                        id='pickup'
                        onClick={() => handleDeliveryMethodChange('pickup')}
                        type="radio" />
                    <label htmlFor="pickup">Самовывоз</label>
                </div>
            </div>
            {
                deliveryMethod === 'delivery' ? <div className="order-processing-contact__address">
                    <div className="__address-form row">
                        <div className="__address-residence col-xxl-7 col-xl-7 col-lg-7 col-md-7 ">
                            <label htmlFor="">Ваш адрес</label>
                            <input
                                {...register('address', {
                                    required: 'Поле обязательно к заполнению!'
                                })}
                                type="text"
                                style={{ borderColor: errors?.address ? "red" : null }}
                                placeholder='Введите ваш адрес' />
                            {errors?.address && <ErrorMessage>{errors.address.message}</ErrorMessage>}
                        </div>
                        <div className="order-processing-contact__address-home col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                            <label htmlFor="">Ваш дом</label>
                            <input
                                {...register('house', {
                                    required: 'Поле обязательно к заполнению!'
                                })}
                                type="text"
                                style={{ borderColor: errors?.house ? "red" : null }}
                                placeholder='Введите ваш дом' />
                            {errors?.house && <ErrorMessage>{errors.house.message}</ErrorMessage>}
                        </div>
                        <div className="order-processing-contact__address-entrance 
                            col-xxl-4 
                            col-xl-4
                            col-lg-4 
                            col-md-4 ">
                            <label htmlFor="">Ваш подъезд</label>
                            <input
                                {...register('entrance', {
                                    required: 'Поле обязательно к заполнению!'
                                })}
                                type="text"
                                style={{ borderColor: errors?.entrance ? "red" : null }}
                                placeholder='Введите ваш подъезд' />
                            {errors?.entrance && <ErrorMessage>{errors.entrance.message}</ErrorMessage>}
                        </div>
                        <div className="
                            order-processing-contact__address-apartment 
                            col-xxl-4 
                            col-xl-4
                            col-lg-4 
                            col-md-4">
                            <label htmlFor="">Номер вашей квартиры</label>
                            <input
                                {...register('apartment_number', {
                                    required: 'Поле обязательно к заполнению!'
                                })}
                                type="text"
                                style={{ borderColor: errors?.apartment_number ? "red" : null }}
                                placeholder='Введите номер квартиры' />
                            {errors?.apartment_number && <ErrorMessage>{errors.apartment_number.message}</ErrorMessage>}
                        </div>
                        <div className="
                            order-processing-contact__address-apartment 
                            col-xxl-4 
                            col-xl-4
                            col-lg-4 
                            col-md-4">
                            <label htmlFor="">Номер этаж</label>
                            <input
                                {...register('floor')}
                                type="text"
                                placeholder='Введите номер этажа' />
                        </div>
                        <div className="order-processing-contact__address-coment">
                            <label htmlFor="">Комментарий к заказу</label>
                            <textarea
                                name="" cols="101"
                                rows="3" placeholder='Введите ваш комментарий'>
                            </textarea>
                        </div>
                    </div>
                </div> : null
            }
        </div>
    )
}

export default OrderProcessingContact;