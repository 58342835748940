import Router from "../routes";
import { Container } from 'react-bootstrap';
import './main.scss';

const Main = () => {
    return (
        <div className="main">
            <Container>
                <Router />
            </Container>
        </div>
    )
}

export default Main;