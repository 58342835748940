//auth modal
export const REGISTER_OPEN = "REGISTER_SHOW";
export const SIGN_OPEN = "SIGNIN_SHOW";
export const REGISTER_CLOSE = "REGISTER_CLOSE";
export const SIGN_CLOSE = "SIGNIN_CLOSE";
export const RECOVERY_CLOSE = "RECOVERY_CLOSE";
export const RECOVERY_OPEN = "SIGNIN_OPEN";
export const CHANGE_PASS_CLOSE = "CHANGE_PASS_CLOSE";
export const CHANGE_PASS_OPEN = "CHANGE_PASS_OPEN";

//burger-menu
export const BURGER_OPEN = "BURGER_OPEN";
export const BURGER_CLOSE = "BURGER_CLOSE";

// auth rest
export const AUTH_REGISTER = "AUTH_REGISTER";
export const AUTH_LOADING = "AUTH_LOADING";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUT_ME = "AUT_ME";
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const TELEGRAM_ME = 'TELEGRAM_ME';

//person rest
export const PERSON_CHANGE = 'PERSON_CHANGE';

//modal menu
export const PURCHASE_OPEN = "PURCHASE_OPEN";
export const PURCHASE_CLOSE = "PURCHASE_CLOSE";
export const FIXED = "FIXED";
export const PROMOTION = 'PROMOTION'
export const ORDER_DETAIL = "ORDER_DETAIL"


// product load
export const PRODUCT_LOAD = "PRODUCT_LOAD";
export const PRODUCT_LOAD_FIlTER = "PRODUCT_LOAD_FIlTER";

export const LOADING_TOGGLE_ACTION = 'LOADING_TOGGLE_ACTION';
export const LOADING_PERSON_ACTION = 'LOADING_PERSON_ACTION';

export const ERROR_DATA = 'ERROR_DATA';
export const CATEGORIES_LOAD = "CATEGORIES_LOAD";
export const SEARCH_PRODUCT_LOAD = "SEARCH_PRODUCT_LOAD"
export const GET_PROMOTION = "GET_PROMOTION";
export const GET_PROMOTION_UUID = "GET_PROMOTION_UUID";
export const CURRENT_PAGE = "CURRENT_PAGE"
export const TOGGLE_PRODUCT_ID = 'TOGGLE_PRODUCT_ID'
// post cart 
export const POST_ADD_TO_CART = "POST_ADD_TO_CART";
export const ADD_PRODUCT_TO_CART = "ADD_PRODUCT_TO_CART";
export const LOAD_CART_PRODUCT = "LOAD_CART_PRODUCT";
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_CART = "DELETE_CART";

//promo code
export const PROMO_CODE = "PROMO_CODE";
export const COUPON = "COUPON"

//order
export const ORDER_GET_DATA = 'ORDER_GET_DATA';
export const ORDER_LOADING = 'ORDER_LOADING';
export const ORDER_PAYMENT_STATUS = 'ORDER_PAYMENT_STATUS';
export const HISTORY_ORDER = "HISTORY_ORDER";
export const LOOK_ORDER = "LOOK_ORDER";


// detail
export const DETAIL_PAGE = 'DETAIL_PAGE';
export const CHANGE_TITLE = 'CHANGE_TITLE';
export const OPEN_DETAIL_MODAL = 'OPEN_DETAIL_MODAL'






