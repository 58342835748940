import './order-processing-list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadCartProduct } from '../../../redux/services/cart-services';
import Loading from "../../loading";

const OrderProcessingList = (props) => {
    const { loading, deliveryMethod } = props;
    const dispatch = useDispatch();
    const carts = useSelector(state => state.productReducer.carts);
    const { cart_items } = carts;
    const counts = cart_items.map(elem => {
        return elem.quantity
    });
    const totalCount = counts.reduce((prev, curr) => {
        return prev + curr
    }, 0);
    useEffect(() => {
        dispatch(loadCartProduct())
    }, [dispatch]);
    
    const discount = carts.promo_code && carts.promo_code.discount_percent
    const pickup = deliveryMethod === 'pickup' ? <span className='order-processing-list__total-price'>Скидкa 10% на самовывоз</span>
    : null
    const totalDiscount = discount !== null ? carts.total * (1 - discount / 100) : carts.total

    const loader = loading ? <Loading /> : 'Оформить заказ'
    const { promo_code_for_products } = carts;
    return (
        <div className='order-processing-list'>
            <div className="order-processing-list__content">
                <h2>Список заказов: ({totalCount})шт</h2>
                <div className='order-processing-list__wrapper'>
                    {
                        carts.cart_items.map((elem, index) => {
                            const { name, sale_price, product } = elem.variant;
                            return (
                                <div key={index} className="order-processing-list__product">
                                    <div className="order-processing-list__left">
                                        <div className="order-processing-list__images">
                                            <img src={`${product.images[0].url}?w=210&h=210`} alt="" />
                                        </div>
                                    </div>
                                    <div className="order-processing-list__right">
                                        <div className="order-processing-list__title">
                                            <span className='order-processing-list__name'>{name}</span>
                                            <span className='order-processing-list_price'>{sale_price}₽</span>
                                        </div>
                                        <div className='order-processing-list__btn-group'>
                                            <span className='order-processing-list__count'>{elem.quantity} шт</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        promo_code_for_products !== null ?
                            <div className='order-processing-list__product'>
                                <div className="order-processing-list__left">
                                    <div className="order-processing-list__images">
                                        <img src={`${promo_code_for_products.variants[0].product.images[0].url}?w=210&h=210`} alt="" />
                                    </div>
                                </div>
                                <div className="order-processing-list__right">
                                    <div className="order-processing-list__title">
                                        <span className='order-processing-list__name'>{promo_code_for_products.variants[0].name}</span>
                                        <span className='order-processing-list_price'>0₽</span>
                                    </div>
                                    <div className='order-processing-list__btn-group'>
                                        <span className='order-processing-list__count'>{promo_code_for_products.variants[0].size} {promo_code_for_products.variants[0].size_unit.name}</span>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                </div>
                <div className="order-processing-list__total">
                    <span>Итоговая стоимость:</span>
                    <span className='order-processing-list__total-price'>{Math.floor(totalDiscount)}₽</span>
                    {
                        discount !== null ? <span className='order-processing-list__total-price'>{discount}%</span> : null
                    }
                    <br />
                    {pickup}
                </div>
                <div className="order-processing-list__btn-pay">
                    <button
                        style={{ opacity: carts.total === 0 ? 0.1 : 1 }}
                        disabled={carts.total === 0 ? true : false}
                        type='submit'>
                        {loader}
                    </button>
                </div>
            </div>
        </div>
    )
}


export default OrderProcessingList;

