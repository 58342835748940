import React from 'react';
import './contact-page-map.scss'

const ContactPageMap = () => {
    return (
        <div className='contact-page-map'>
            <h1>Карта</h1>
            <iframe title="Карта местоположения" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2028.0838650310714!2d32.0037405!3d59.448352799999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46bc521bf0faef21%3A0x7025ab87c5fedd4c!2z0KHRg9GI0Lgg0KLQsNC50Lw!5e0!3m2!1sru!2skg!4v1642514638655!5m2!1sru!2skg"></iframe>
        </div>
    );
};

export default ContactPageMap;