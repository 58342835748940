import React from 'react';
import LocationComponent from '../../location';
import logo from '../../../assets/img/logo.png';
import './footer-contact.scss';
import { useNavigate } from 'react-router-dom';
import * as Scroll from 'react-scroll'
import { FaWhatsapp, FaTelegramPlane } from 'react-icons/fa';
import { FaVk } from 'react-icons/fa'
const FooterContact = () => {
    const scroll = Scroll.animateScroll;
    const navigate = useNavigate()
    return (
        <div className="footer-contact">
            <div onClick={() => {
                scroll.scrollTo(0, 0)
                navigate('/')
            }} className="footer-contact__logo">
                <img src={logo} alt="" />
            </div>
            <div className="footer-contact__social">
                <div className='footer-contact__icons'>
                    <a target='_blank' rel="noreferrer" href={'https://vk.com/sushitime.kirishi'}>
                        <FaVk />
                    </a>
                    <a target='_blank' rel="noreferrer" href="https://api.whatsapp.com/send/?phone=79046334777&text&type=phone_number&app_absent=0">
                        <FaWhatsapp />
                    </a>
                    <a target='_blank' rel="noreferrer" href="https://tlgg.ru/@sushitime47Bot">
                        <FaTelegramPlane />
                    </a>
                </div>
            </div>
            <div className="footer-contact__location">
                <LocationComponent />
            </div>
        </div>
    )
}

export default FooterContact;