import { Spinner } from 'react-bootstrap';
import './loading.scss'
const Loading = () => {
    return (
        <div className='loading'>
            <div className='loading-absolute'></div>
            <Spinner animation="grow" variant="dark" />
        </div>
    )
}

export default Loading;